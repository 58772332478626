.reviews-manager-container {
    padding: 20px;
  }
  
  .reviews-container {
    max-width: 800px; /* Ajusta el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    padding: 20px;
    background-color: #f9f9f9; /* Un fondo ligero para destacar */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para darle profundidad */
  }
  
  .pending-review {
    background-color: #ffdcc8; /* Color de fondo para reseñas pendientes */
  }
  
  .approved-review {
    background-color: #afffc7; /* Color de fondo para reseñas aprobadas */
  }
  
  .botonRese {
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .reviews-container .ant-card {
    margin-bottom: 20px; /* Espaciado entre tarjetas */
  }
  .divTitulo{
    text-align: center;
  }