* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
  margin: 0;
  padding: 0;
}

.home-container {
  text-align: center;
  padding: 15px;
  background-color: #ffffff;
  max-width: 100%;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.home-logo {
  width: 200px;
  margin-bottom: 20px;
}

.home-title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 30px;
}

.departments-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.dept-item {
  padding: 20px;
  border-radius: 8px;
  background-color: #f3f3f3;
  margin-bottom: 20px;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.dept-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.dept-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.dept-carousel {
  flex: 1;
  width: 100%;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.lblTituloTarjetaDepto {
  font-size: 3em;
  color: #0f0b46;
  margin-bottom: 20px;
}

.lblDetalleDepto {
  font-size: 1.4em;
  color: #666666;
}

.lblPrecioDepto {
  font-size: 30px;
  color: #030303;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.lblEdificioDepto,
.lblDireccionDepto {
  font-size: 17px;
  color: #666666;
  font-weight: bold;
  margin-bottom: 20px;
}
.lblCercanoDepto {
  font-size: 17px;
  color: #666666;
  font-weight: bold;
  margin-bottom: 20px;
}

.why-rent-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.reason-item {
  flex: 1 1 30%; /* Hace que los elementos se dispongan horizontalmente con un tamaño flexible */
  margin: 0 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reason-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.reviews-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.review-item {
  background-color: #fff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.lblTitulomotivosDepto {
  font-size: 1.2em;
  color: #030303;
  font-weight: bold;
  margin-bottom: 10px;
}

.dept-amenities {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.amenity-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.amenity-icon {
  font-size: 1.5em;
  color: #5e5e5e;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .dept-content {
    flex-direction: column;
  }
  
  .dept-info {
    margin-bottom: 20px;
  }

  .dept-carousel {
    width: 100%;
  }

  .home-title {
    font-size: 2em;
  }

  .lblTituloTarjetaDepto {
    font-size: 2em;
  }

  .lblPrecioDepto {
    font-size: 24px;
  }

  .reason-item {
    flex: 1 1 100%; /* Cambia la disposición a columnas en pantallas pequeñas */
    margin-bottom: 20px;
  }

  .review-item {
    margin: 20px 0;
  }
}
.faq-item {
  background-color: #f3f3f3;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-question {
  background-color: #ffffff;
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  text-align: left;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e0e0e0;
}

.faq-answer {
  margin-top: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
  color: #666;
  font-weight: bold;
}
