.contact-container {
  text-align: center;
  padding: 20px;
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Permite que los elementos se ajusten y pasen a la siguiente línea */
}

.social-buttons a, .social-buttons button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  flex: 1 1 100%; /* Hace que los botones ocupen el 100% del ancho disponible en pantallas pequeñas */
  max-width: 300px; /* Limita el ancho máximo de los botones */
  justify-content: center; /* Centra el contenido dentro del botón */
}

.whatsapp-button {
  background-color: #25D366;
}

.whatsapp-button:hover {
  background-color: #1DA851;
}

.instagram-button {
  background: linear-gradient(45deg, #833AB4, #FD1D1D, #FCB045);
}

.instagram-button:hover {
  background: linear-gradient(45deg, #9b4fbb, #f03b3b, #ffbe60);
}

.gmail-button {
  background-color: #D44638;
}

.gmail-button:hover {
  background-color: #bb3d2f;
}

.social-buttons svg {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .social-buttons {
    flex-direction: column; /* Coloca los botones uno debajo del otro en pantallas pequeñas */
    gap: 10px; /* Espacio entre los botones en pantallas pequeñas */
  }
}
