* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

body {
  font-family: 'Arial', sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s, color 0.3s;

}

/* Asegura que el contenedor principal ocupe al menos el 100% de la altura disponible */
.container {
  flex: 1;
  min-height: calc(100vh - 80px); /* Ajusta 80px si el navbar ocupa más o menos espacio */
  display: flex;
  flex-direction: column;
}

/* Estilos para el navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  z-index: 2; /* Asegura que el navbar esté siempre por encima del contenido */
}

.navbar-logo img {
  width: 50px;
}

/* Botón de menú tipo hamburguesa */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  font-size: 1.5em;
}

.hamburger span {
  background: #333;
  height: 3px;
  margin: 4px 0;
  width: 25px;
}

/* Estilo de enlaces del navbar */
.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  color: #333;
  text-decoration: none;
  font-size: 1em;
  position: relative;
  padding: 10px 0;
  display: inline-block;
  transition: color 0.3s;
  font-family:Arial, Helvetica, sans-serif;
}

.navbar-links a:hover {
  color: #007bff; /* Color al hacer hover */
}

/* Responsivo para móviles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #e0e0e0;
    position: absolute;
    top: 60px; /* Ajustado para que coincida con la altura del navbar */
    left: 0;
    z-index: 1;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li {
    margin: 10px 0;
    text-align: center;
  }
}

/* Estilos para el contenido */
.heading {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

.image {
  width: 300px;
  margin-bottom: 20px;
}

/* Estilos para el footer */
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #4b4d70;
  font-size: 1em;
  width: 100%;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
}

.footer a:hover {
  color: #aaaaaa; /* Cambié el color del hover para un contraste mejorado */
}

/* Responsivo para móviles */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-left, .footer-right {
    margin: 10px 0;
  }
}

/* Ajustes específicos para el menú en mobile */
@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
}
@media (min-width: 769px) {
  .desktop-menu {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .mobile-menu {
    display: none;
  }
}




/* Estilo para el enlace activo */
.navbar-links .active-link {
  color: #007bff; /* Color del enlace activo */
}

.navbar-links .active-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #007bff; /* Color de la barra azul */
  border-radius: 2px;
}
.navbar-links a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #007bff; /* Color de la barra azul */
  border-radius: 2px;
  transition: width 0.3s ease;
}

.navbar-links a:hover::after {
  width: 100%; /* Barra azul completa al hacer hover */
}

.navbar-links .active-link::after {
  width: 100%; /* Barra azul completa en el enlace activo */
}