.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-viewer-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
  }
  
  .image-viewer-content img {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  
  .navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .navigation button {
    background: none;
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    padding: 10px;
  }
  