.departments-form {
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow: hidden; /* Asegura que el contenido no se desborde */
}

.departments-form * {
  box-sizing: border-box;
}

.departments-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.departments-form input,
.departments-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.departments-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.departments-form button:hover {
  background-color: #0056b3;
}

.photo-upload-container {
  margin-bottom: 20px;
}

.photo-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.photo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  text-align: center;
}

.photo-item img {
  width: 100%;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.photo-order-input {
  margin-top: 5px;
  width: 60px;
}

.departments-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.departments-list li {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.departments-list h3 {
  margin-top: 0;
  color: #007bff;
}

.departments-list p {
  margin: 5px 0;
  color: #333;
}

.departments-list p a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: block;
  color: #007bff;
  text-decoration: none;
}

.departments-list p a:hover {
  text-decoration: underline;
}

.departments-list .photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.departments-list .photos img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.departments-list .actions {
  margin-top: 10px;
}

.departments-list button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.departments-list button:hover {
  background-color: #0056b3;
}

.centered-div {
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.centered-div ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.btnCancelar {
  margin-top: 10px;
}

.add-department-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2em;
}

.add-department-button:hover {
  background-color: #218838;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 5px;
}
