.reviewsForm-container {
  max-width: 500px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.reviews-form {
  display: flex;
  flex-direction: column;

}

.reviews-input,
.reviews-textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.reviews-textarea {
  height: 100px;
  resize: none;
}

button[type="submit"] {
  padding: 10px;
  background-color: #4b4d70;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

button[type="submit"]:hover {
  background-color: #dbdbdb;
}

.reviews-stars {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}
