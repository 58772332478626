.deptosPage-container {
    text-align: center;
    background-color: #ffffff;
    max-width: 100%;
    overflow-x: hidden; /* Evita el desbordamiento horizontal */
  }
  
  .departmentos-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden; /* Evita el desbordamiento horizontal */
  }
  
  .depto-item {
    padding: 10px;
    border-radius: 8px;
    background-color: #f3f3f3;
    margin: 10px;
    overflow-x: hidden; /* Evita el desbordamiento horizontal */
  }
  
  .depto-info {
    text-align: left;
  }
  
  .depto-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden; /* Evita el desbordamiento horizontal */
  }
  
  .depto-amenities {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .Deptoamenity-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .Deptoamenity-icon {
    font-size: 1.5em;
    color: #5e5e5e;
  }
  
  .toggle-content {
    text-align: center;
    margin-top: 15px;

  }
  
  .toggle-button {
    background-color: #dbdbdb; /* Color de fondo similar al contenedor */
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 1.5rem; /* Ajusta el tamaño del texto/icono si es necesario */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Para que ocupe todo el ancho del contenedor */
  }
  
  .toggle-button:hover {
    background-color: #e0e0e0; /* Color de fondo al pasar el mouse */
  }
  .extra-content {
    margin-top: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ccc;
  }
  
  .map-container, .faq-container {
    margin-top: 10px;
  }
  
  .schedule-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #25D366; /* Color verde de WhatsApp */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .schedule-button .whatsapp-icon {
    margin-right: 8px;
    font-size: 1.5em; /* Tamaño del ícono */
  }
  
  .schedule-button:hover {
    background-color: #20b954; /* Un poco más oscuro al pasar el mouse */
  }
  .schedule-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px; /* Añade espacio si es necesario */
    flex-direction: column;
  }