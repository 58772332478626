.department-carousel {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    overflow: hidden; /* Oculta las partes del carrusel fuera de la vista */
  }
  
  .department-carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Añade una transición suave */
  }
  
  .department-carousel-slide {
    min-width: 100%; /* Cada slide ocupa el 100% del ancho del contenedor */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .department-carousel-dots {
    text-align: center;
    margin-top: 10px;
  }
  
  .dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  .dot.active {
    background-color: #717171;
  }
  
  .dot:hover {
    background-color: #555;
  }
  