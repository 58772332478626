.image-carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden; /* Esconde las imágenes que no están en la vista */
  margin: 0 auto;
}

.image-carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Efecto de desplazamiento suave */
}

.image-carousel-slide {
  min-width: 100%; /* Cada slide toma el 100% del ancho del carrusel */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-carousel-image {
  width: 100%;
  height: 300px; /* Fija la altura a 300px para un tamaño uniforme */
  object-fit: cover; /* Asegura que la imagen se ajuste sin distorsión */
  border-radius: 8px;
}

.image-carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
}

.image-prev {
  left: 10px;
}

.image-next {
  right: 10px;
}
